import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import { styled, keyframes } from "@mui/material/styles";

const fadeInBlur = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
  }
`;

let theme = createTheme();

theme.typography.heading = {
  fontSize: "1.2rem",
  "@media (min-width:768px)": {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.0rem",
  },
};

const FullScreenBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const blueColor = "#2bb4f6";

export default function Hero() {
  const handleBookConsultation = () => {
    window.open("https://calendly.com/hello-yahweh", "_blank");
  };

  return (
    <FullScreenBox
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          height: "100%",
          px: 4,
          pt: { xs: 4, sm: 8, md: 0 },
          mt: { xs: 0, md: -25 },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: "flex-start",
            width: { xs: "100%", sm: "70%" },
            mt: { xs: 0, md: 0 },
          }}
        >
          <Typography
            variant="heading"
            sx={{
              fontSize: {
                xs: "clamp(2rem, 6vw, 2.5rem)",
                sm: "clamp(3rem, 8vw, 3rem)",
              },
              opacity: 0,
              animation: `${fadeInBlur} 1.5s ease`,
              animationDelay: "0s",
              animationFillMode: "forwards",
              textAlign: "left",
            }}
          >
            Elevate your business
            <div style={{ color: blueColor }}>
              through innovation and expertise
            </div>
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "clamp(1rem, 4vw, 1.5rem)", sm: "1.25rem" },
              opacity: 0,
              animation: `${fadeInBlur} 1.5s ease`,
              animationDelay: "1s",
              animationFillMode: "forwards",
              textAlign: "left",
              maxWidth: "600px",
              mt: 0, // Set marginTop to 0
            }}
          >
            Our mission is to offer comprehensive solutions across a diverse
            range of domains, including technology, design, branding, marketing,
            content creation, sales, event planning, and more.
          </Typography>
          <Box sx={{ marginTop: "150px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBookConsultation}
              sx={{
                mt: 2,
                px: 2,
                py: 0.5,
                fontSize: { xs: "1rem", sm: "1rem" },
                fontWeight: "bold",
                borderRadius: "8px",
                backgroundColor: blueColor,
                boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.2)`,
                "&:hover": {
                  backgroundColor: blueColor,
                },
                transition: "background-color 0.3s ease",
                opacity: 0,
                animation: `${fadeInBlur} 1.5s ease`,
                animationDelay: "1.5s",
                animationFillMode: "forwards",
              }}
            >
              Book a Consultation
            </Button>
          </Box>
        </Stack>
      </Container>
    </FullScreenBox>
  );
}
